<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No tienes asignado ningún parte de trabajo"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :page-loaded="pageLoaded"
    >
      <template
        #listItem="slotProps"
      >
        <accion-asignado-fecha-fijada-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './AccionAsignadoFechaFijadaListData'
import AccionAsignadoFechaFijadaListItem from './components/AccionAsignadoFechaFijadaListItem'
import { get } from 'vuex-pathify'
import { toDate } from '@/utils/date'

const pageStoreName = 'pagesAccionAsignadoFechaFijadaList'

export default {
  components: {
    AccionAsignadoFechaFijadaListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const ffijada = toDate(this.routeParams.ffijada)
        const [dataset, metadata] = await Data.selectTotalesMaterial(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.clasificacion,
          ffijada,
          this.usuarioIdtecnico
        )
        await this.loadItemsBase(dataset, metadata)
        if (this.items.length > 0) {
          this.title = `${this.items[0].maccion_clasif_desc} - ${this.$options.filters.shortDate(ffijada)}`
        } else {
          this.title = this.$options.filters.shortDate(ffijada)
        }
      } finally {
        this.loadingData = false
      }
    }
  }
}
</script>
