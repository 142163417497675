import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { endOfDay } from '@/utils/date'
import { PARTE_TRABAJO, ACCION_EJEC } from '@/utils/consts'

export default {
  async selectTotalesMaterial (Vue, filter, search, sorter, page, clasificacion, ffijada, idtecnico) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGTE('ffijada', ffijada)
      .addLTE('ffijada', endOfDay(ffijada))
      .addExact('clasificacion', clasificacion)
      .addExact('parte_trabajo_estado', PARTE_TRABAJO.estados.pendiente)
      .addExact('idest_accion_ejec', ACCION_EJEC.estados.pendiente)
      .addExact('idtecnico', idtecnico)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('material_sistema_descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.materialSistemaDescripcion.value) {
      apiFilter.addILike(filter.materialSistemaDescripcion.field, filter.materialSistemaDescripcion.value)
    }
    const resp = await Vue.$api.call('ordenTrabajoMatsist.selectTotalesAcciones', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
